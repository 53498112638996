import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';


export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { token } = params;
    localStorage.setItem('session_token', token);
    return Promise.resolve();
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('session_token');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('session_token');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('session_token')
      ? Promise.resolve()
      : Promise.reject();
  }

  return Promise.reject('Unknown method');
}
