import React from 'react';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';

import { UserList } from './datatypes/user/user.js';
import {
  PostList,
  PostEdit,
  PostCreate,
} from './datatypes/posts/posts.js';
import Dashboard from './customviews/Dashboard.js';
import authProvider from './authProvider.js';


const dataProvider = jsonServerProvider('http://jsonplaceholder.typicode.com');

const App = () => (
  <Admin
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource
      name="posts"
      list={PostList}
      edit={PostEdit}
      create={PostCreate}
      icon={PostIcon}
    />
    <Resource
      name="users"
      list={UserList}
      icon={UserIcon}
    />
  </Admin>
);

export default App;
